import React from 'react'

import Footer from '../components/footer'

const ContactPage = () => {
    return (
        <div>
            <h1>Contact</h1>
            <p>Under Construction, how did you get here?</p>
            <Footer/>
        </div>
    )
}

export default ContactPage